
import { mdiMonitor, mdiCog } from "@mdi/js";

export default {
  layout: "maintenance",

  data() {
    return {
      mdiMonitor,
      mdiCog,
    };
  },

  mounted() {
    document.body.classList.add("maintenance-body");
  },

  beforeDestroy() {
    document.body.classList.remove("maintenance-body");
  },
};
