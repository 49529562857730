import { render, staticRenderFns } from "./maintenance.vue?vue&type=template&id=18fdc6b6"
import script from "./maintenance.vue?vue&type=script&lang=js"
export * from "./maintenance.vue?vue&type=script&lang=js"
import style0 from "./maintenance.vue?vue&type=style&index=0&id=18fdc6b6&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/node_modules/vuetify/lib/components/VIcon/VIcon.js').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
